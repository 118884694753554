import React from 'react'
import { Container } from 'react-bootstrap'
import IngredientsCaloriesHistogram from '../components/charts/IngredientsCaloriesHistogram'
import RecipeNationalityPieChart from '../components/charts/RecipeNationalityPieChart'
import RestrauntRatingBoxPlot from '../components/charts/RestrauntRatingBoxPlot'

const ProviderVisualizations = () => {

    return (
        <React.Fragment>
            {/* Provider Data Visualizations */}
                {/*  Ideas:
                1. histogram of calories in ingredients
                2. pie chart of nationalities in food recipes
                3. bar chart counting # of each price category ($)
            */}
            <Container>
                <h1 className='header-1 mt-4'>Provider Visualizations</h1>
                <h3 className='header-1 mt-2'>Calories in Ingredients</h3>
                <IngredientsCaloriesHistogram />
                <h3 className='header-1 mt-2'>Recipe Nationalities</h3>
                <RecipeNationalityPieChart />
                <h3 className='header-1 mt-2'>Restraunt Price vs Rating</h3>
                <RestrauntRatingBoxPlot />
            </Container>
        </React.Fragment>
    )
}

export default ProviderVisualizations