import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col } from "react-bootstrap";
import axios from 'axios'
import { useEffect, useState } from 'react'

// Simulate fetching player data (actual api only fetches player that matches id)
// import playersData from '../data/PlayersData'

const PlayerInstance = () => {
  let { id } = useParams()

  const [player, setPlayer] = useState([]);
  const [sport, setSport] = useState([]);
  const [team, setTeam] = useState([]);
  const [league, setLeague] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [otherPlayer0, setOtherPlayer0] = useState([]);
  const [otherPlayer1, setOtherPlayer1] = useState([]);
  const [otherPlayer2, setOtherPlayer2] = useState([]);

  useEffect(() => {
    const loadPlayer = async () => {
      axios.get("https://api.allthesports.info/players/" + id)
        .then(response => {
          setPlayer(response.data)
          console.log("Response: ")
          console.log(response.data)
          axios.get("https://api.allthesports.info/sports/" + response.data.sport_id)
            .then(response => {
              setSport(response.data)
              console.log("Sport: ")
              console.log(response.data)
            })
            .catch(error => {
              console.error(error);
            });

          axios.get("https://api.allthesports.info/teams/" + response.data.team_id)
            .then(response => { 
              setTeam(response.data)
              // Get Players from Team
              axios.get("https://api.allthesports.info/players/team/" + response.data.id)
                .then(response => {
                  const randomIndexes = [];

                  while (randomIndexes.length < 3) {
                    const randomIndex = Math.floor(Math.random() * response.data.length);
                    if (!randomIndexes.includes(randomIndex) && response.data[randomIndex].id !== id) {
                      randomIndexes.push(randomIndex);
                    } 
                  }

                  // Set players
                  setOtherPlayer0(response.data[randomIndexes[0]]);
                  setOtherPlayer1(response.data[randomIndexes[1]]);
                  setOtherPlayer2(response.data[randomIndexes[2]]);
                  
                })
                .catch(error => {
                  console.error(error);
                });
            })
            .catch(error => {
              console.error(error);
            });

          axios.get("https://api.allthesports.info/leagues/" + response.data.league_id)
            .then(response => {
              setLeague(response.data)
              console.log("League: ")
              console.log(response.data)
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
      setLoaded(true);
    }
    if (!loaded) {
      loadPlayer();
    }
  });

  return (
    <React.Fragment>
      <Container fluid className="text-center">
        {/* Refactor next 2 lines. Row/col here only used to create smaller container */}
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            className="border border-2 rounded border-secondary mt-4 p-4"
          >
            {/* Player's image */}
              <img
                src={player.photo}
                alt="Player"
                style={{ width: "auto", height: "200px" }}
                className="mx-auto d-block"
              />

            <h1 className="display-6 ">{player.name}</h1>

          <hr />

          {/* Player Stats */}
          <Row className="mt-4">
              <h3 className="mb-4">Player Information</h3>
              <Col>
                <Row>
                  <h4>Age</h4>
                </Row>
                <Row>
                  <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                    {player.age ? player.age : "No age available for " + player.name + "."}
                  </p>
                </Row>
              </Col>
              <Col>
                <Row>
                  <h4>Weight</h4>
                </Row>
                <Row>
                  <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                    {player.weight ? player.weight : "No weight available for " + player.name + "."}
                  </p>
                </Row>
              </Col>
              <Col>
                <Row>
                  <h4>Height</h4>
                </Row>
                <Row>
                  <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                    {player.height ? player.height : "No height available for " + player.name + "."}
                  </p>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <h4>Biography</h4>
            </Row>
            <Row>
              <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                {player.bio ? player.bio : "No biography available for " + player.name + "."}
              </p>
            </Row>

            <hr />

            {/* Misc */}

            <div>
              <Row className="mt-4">
                {/* Left Line */}
                <Col>
                  <h3 className="mb-4">Player Team</h3>
                  <Col key = {team.id}>
                    <div className="card">
                      <a href={'/teams/' + team.id}>
                      <img
                        src={team.logo}
                        alt="Team's logo"
                        style={{ width: "100px", height: "100px" }}
                        className="mx-auto d-block"
                      />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{team.name}</h5>
                        <a href={'/leagues/' + league.id}>
                        <img
                          src={league.logo}
                          alt="League's logo"
                          style={{ width: "100px", height: "100px" }}
                          className="mx-auto d-block"
                        />
                        </a>
                        <p className="card-text">{league.name}</p>
                      </div>
                    </div>
                </Col>
              </Col>
                
                {/* Center Line */}
                <Col>
                  <h3 className="mb-4">Other Information</h3>
                  <Row className="mt-4">
                    <h4>Sport</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                      {sport.name ? sport.name : "No sport available for " + player.name + "."}
                    </p>
                  </Row>

                  <Row className="mt-4">
                    <h4>Current Team</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                      {team.name ? team.name : "No team available for " + player.name + "."}
                    </p>
                  </Row>

                  <Row className="mt-2">
                    <h4>League</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                      {league.name ? league.name : "No league available for " + player.name + "."}
                    </p>
                  </Row>

                  <Row className="mt-2">
                    <h4>Birthday</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                      {player.birthday ? player.birthday : "No birthday available for " + player.name + "."}
                    </p>
                  </Row>
                </Col>

                {/* Right Line */}
                <Col>
                  <h3 className="mb-4">Fellow Players</h3>
                  <Col key = {otherPlayer0.id}>
                    <div className="card">
                      <a href={'/players/' + otherPlayer0.id}>
                      <img
                        src={otherPlayer0.photo}
                        alt="otherPlayer0"
                        style={{ width: "65px", height: "65px" }}
                        className="mx-auto d-block"
                      />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{otherPlayer0.name}</h5>
                      </div>
                    </div>
                    </Col>
                    <Col key = {otherPlayer1.id}>
                    <div className="card">
                      <a href={'/players/' + otherPlayer1.id}>
                      <img
                        src={otherPlayer1.photo}
                        alt="otherPlayer1"
                        style={{ width: "65px", height: "65px" }}
                        className="mx-auto d-block"
                      />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{otherPlayer1.name}</h5>
                      </div>
                    </div>
                    </Col>
                    <Col key = {otherPlayer2.id}>
                    <div className="card">
                      <a href={'/players/' + otherPlayer2.id}>
                      <img
                        src={otherPlayer2.photo}
                        alt="otherPlayer2"
                        style={{ width: "65px", height: "65px" }}
                        className="mx-auto d-block"
                      />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{otherPlayer2.name}</h5>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>


            {/* Insert Media Below */}

            { player.video_src &&
              <Row>
                <iframe title=" " width="560" height="815" src={player.video_src} frameborder="0" allowfullscreen></iframe>
              </Row>
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default PlayerInstance