import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import TeamInstance from './scenes/TeamInstance.jsx'
import LeagueInstance from './scenes/LeagueInstance.jsx'
import PlayerInstance from './scenes/PlayerInstance.jsx'

// Import Scenes
import Layout from './scenes/Layout.jsx'
import Home from './scenes/Home.jsx'
import About from './scenes/About.jsx'
import Teams from './scenes/Teams.jsx'
import Leagues from './scenes/Leagues.jsx'
import Players from './scenes/Players.jsx'
import NotFound from './scenes/NotFound.jsx'
import Search from './scenes/Search.jsx'
import Visualization from './scenes/OurVisualization.jsx'
import ProviderVisualization from './scenes/ProviderVisualizations.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/teams' element={<Teams />} />
          <Route path='/teams/:id' element={<TeamInstance />} />
          <Route path='/leagues' element={<Leagues />} />
          <Route path='/leagues/:id' element={<LeagueInstance />} />
          <Route path='/players' element={<Players />} />
          <Route path='/players/:id' element={<PlayerInstance />} />
          <Route path='/search' element={<Search />} />
          <Route path='/OurVisualizations' element={<Visualization />} />
          <Route path='/ProviderVisualizations' element={<ProviderVisualization />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
