import React from "react";
import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';

const ToolCard = ({
    name,
    link,
    description,

}) => {
    return (
        <a href={link} style={{ textDecoration: 'none' }}>
            <Card style={{ width: '15rem', height: '11rem', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', cursor: 'pointer', transition: 'background-color 0.3s ease-in-out', backgroundColor: '#fff' }} 
                onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#FFA07A' }}
                onMouseOut={(e) => { e.currentTarget.style.backgroundColor = '#fff' }}
                >
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70%', textAlign: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Card.Title style={{ marginBottom: '0.5rem' }}><strong>{name}</strong></Card.Title>
                    <Card.Text style={{ marginBottom: '1rem', fontSize: '15px' }}>{description}</Card.Text>
                    </div>
                </Card.Body>
            </Card>
        </a>
    );
}

export default ToolCard;
