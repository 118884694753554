// import React, { useState, useEffect, memo } from 'react'
import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'
import axios from 'axios'
import './About.css';
import { Col, Row } from 'react-bootstrap';
import DevCard from '../components/DevCard';
import Container from 'react-bootstrap/Container';
import ApiCard from '../components/ApiCard';
import ApiCardData from '../components/ApiCardData';
import tools from '../components/ToolCardData';
import ToolCard from '../components/ToolCard';
import team from '../components/DevCardData';
//Took inspiration from IDB group 3: https://gitlab.com/anshmor/cs-373-idb-group-3

const names = {
  'Jerome Conkright': 0,
  'Daniel Jeng': 1,
  'Aaron Lee': 2,
  'Harman Sandhu': 3,
  'Brady Wilkin': 4,
}

const accessToken = 'glpat-U2Q8VeUVQQrLkZ--F2j7'
// const projectID = '43420803 ';

const client = axios.create({
  baseURL: 'https://gitlab.com/api/v4/projects/43420803',
  headers: {
    'Authorization': `Bearer ${accessToken}`,
  },
})

export default function About() {
  const [commitsState, updateCommits] = useState([0, 0, 0, 0, 0])
  const [issuesState, updateIssues] = useState([0, 0, 0, 0, 0])
  const [loading, setLoading] = useState(true)
  const [totalCommits, setTotalCommits] = useState(0) // new state variable
  const [totalIssues, setTotalIssues] = useState(0) // new state variable

  useEffect(() => {
    let newCommits = [0, 0, 0, 0, 0]
    client.get('repository/contributors').then((response) => {
      response.data.forEach((element) => {
        const { name, email, commits } = element
        team.forEach((member) => {
          if (member.name === name || member.email === email) {
            newCommits[names[member.name]] += commits
          }
        })
      })
      updateCommits(newCommits)
      setTotalCommits(newCommits.reduce((acc, val) => acc + val, 0)) // calculate totalCommits
    })

    let newIssues = [0, 0, 0, 0, 0]
    team.forEach((member) => {
      client
        .get('issues_statistics?assignee_username=' + member.gitlab_username)
        .then((response) => {
          newIssues[names[member.name]] +=
            response.data['statistics']['counts']['closed']
          updateIssues(newIssues)
          setTotalIssues(newIssues.reduce((acc, val) => acc + val, 0)) // calculate totalIssues
        })
    })
    setLoading(false)
  }, [])


  if (loading) {
    // display loading message or spinner
    return <p>Loading data...</p>
  }

  return (
    <>
      <div className='about-container'>
        <Container className="p-4" style={{ backgroundColor: '#F8F8F8' }}>
          <h1 className="text-center pb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>What is AllTheSports?</h1>
          <Card style={{ backgroundColor: '#ffebe2', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', borderRadius: '10px', border: '1px solid #ccc' }}>
            <div className="d-flex justify-content-center">
              <div className="text-center px-4 py-3">
                <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#555' }}>
                  AllTheSports is a website dedicated to helping people learn more about their favorite sports leagues, teams, and players. Whether you're a die-hard fan or a casual viewer, our website has something for everyone.
                </p>
                <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#555' }}>
                  We provide up-to-date information and statistics on all the major sports, including Football, Tennis, Basketball, Ice Hockey, Volleyball, and Handball. Our goal is to be a one-stop-shop for all your sports-related needs.
                </p>
                <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#555' }}>
                  We hope you enjoy our website and find it useful!
                </p>
              </div>
            </div>
          </Card>
        </Container>


        <Container className="p-4">
          <br/>
          <br/>
        </Container>
      <div className="bg-light">
        <h1 className="text-center display-3 font-weight-heavy text-black mb-4">Meet the allthesports team</h1>
        <Row className="gap-4 mt-4 mb-4">
          {team.map((member) => (
            <Col key={member.name}>
              <DevCard
              name = {member.name}
              gitlab_username = {member.gitlab_username}
              github_username = {member.github_username}
              email = {member.email}
              image = {member.image}
              bio = {member.bio}
              role = {member.role}
              commits={commitsState[names[member.name]]}
              issues={issuesState[names[member.name]]}
              tests = {member.tests}
              />
            </Col>
          ))}
        </Row>
        <Row>
        <Card style={{ border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', height: '100%' }}>
            <Row style={{ alignItems: 'center', height: '100%' }}>
              <Col style={{ borderRight: '2px solid #ccc', textAlign: 'center' }}>
                <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0' }}>Total Commits:</Card.Title>
                <Card.Text style={{ fontSize: '1.2rem', margin: '0' }}>{totalCommits}</Card.Text>
              </Col>
              <Col style={{ borderRight: '2px solid #ccc', textAlign: 'center' }}>
                <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0' }}>Total Issues:</Card.Title>
                <Card.Text style={{ fontSize: '1.2rem', margin: '0' }}>{totalIssues}</Card.Text>
              </Col>
              <Col style={{ borderRight: '2px solid #ccc', textAlign: 'center' }}>
                <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0' }}>Total Unit Tests:</Card.Title>
                <Card.Text style={{ fontSize: '1.2rem', margin: '0' }}>68</Card.Text>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <a href="https://gitlab.com/dbjeng/cs373-idb-1" style={{ textDecoration: 'none' }}>
                <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0', transition: 'background-color 0.3s ease-in-out' }} 
                    onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#FFA07A' }}
                    onMouseOut={(e) => { e.currentTarget.style.backgroundColor = '#fff' }}>
                      GitLab Repo
                </Card.Title>
                </a>
              </Col>
            </Row>
          </Card>
        </Row>
        </div>

        <hr style={{ border: '5px solid black' }} />

        <h2 className="d-flex justify-content-center p-4 ">Data Sources:</h2>
        <Row className="gap-4 mt-4 mb-4">
            {ApiCardData.map((card, index) => (
              <Col key={index}>
                <ApiCard
                  name={card.name}
                  description={card.description}
                  url={card.url}
                  image={card.image}
                />
              </Col>
            ))}
        </Row>


        <hr style={{ borderTop: '5px solid black' }} />

        <h2 className="d-flex justify-content-center p-4 ">Tools</h2>
        <p className="d-flex justify-content-center">Feel Free to Click on them!</p>
        <Row className="gap-4 mt-4 mb-4">
            {tools.map((card, index) => (
              <Col key = {index}>
                <ToolCard
                  name={card.name}
                  description={card.description}
                  link={card.link}
                />
              </Col>
            ))}
        </Row>
        
        <hr style={{ borderTop: '5px solid black' }} />

        <h2 className="d-flex justify-content-center p-4 ">Integrating Disperate Data</h2>
        <div className="d-flex justify-content-center p-4">
          <p className='d-flex justify-content-center p-4'>We have integrated data from multiple sources to create a single, cohesive product. We have used the Sports Score API to get data on players, teams and leagues. We have used the YouTube API to get videos for players. We have used the Google Maps Embed API to get maps for stadiums. We have used the TheSportDB API to get data on players. We have used the Postman API to get data from our database. We have used the GitLab API to get data from our GitLab repository. Our models are synced up with each sort of model refering to another model.</p>
        </div>
      </div>
    </>
  )
}
