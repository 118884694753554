const tools = [
    {
      id: 1,
      name: "GitLab",
      link: "https://gitlab.com",
      description: "Stores and helps us manage different versions of our code."
    },
    {
      id: 2,
      name: "Postman",
      link: "https://postman.com",
      description: "Used for testing third-party API endpoints and developing our own API."
    },
    {
      id: 3,
      name: "VS Code",
      link: "https://code.visualstudio.com/",
      description: "Integrated Development Environment"
    },
    {
      id: 4,
      name: "Microsoft Teams",
      link: "https://www.microsoft.com/en-us/microsoft-teams/group-chat-software",
      description: "Communication via messages and video calls"
    },
    {
      id: 5,
      name: "Zoom",
      link: "https://zoom.us/",
      description: "Used for a while to meet online while Teams wasn't working for a teammate."
    },
    {
      id: 6,
      name: "Axios",
      link: "https://www.axios.com/",
      description: "Tool to send HTTP requests to client library."
    },
    {
      id: 7,
      name : "Docker",
      link: "https://www.docker.com/",
      description: "Creates environment to be able to use tools on and to test with."
    },
    {
      id: 8,
      name : "Node Package Manager",
      link: "https://www.npmjs.com/",
      description: "Used to launch Node and its packages."
    },
    {
      id: 9,
      name : "React",
      link: "https://react.dev/",
      description: "Javascript library used for front end development."
    },
    {
      id: 10,
      name : "React-Bootsrap",
      link: "https://react-bootstrap.github.io/",
      description: "CSS framework built on React, enhances visual aspect of website."
    },
    {
      id: 11,
      name : "React Router",
      link: "https://reactrouter.com/en/main",
      description: "Library for routing in React"
    },
    {
      id: 12,
      name : "Pagination",
      link: "https://getbootstrap.com/docs/4.0/components/pagination/",
      description: "Tool that helps divide instances into multiple pages."
    },
    {
      id: 13,
      name : "AWS RDS",
      link: "https://aws.amazon.com/rds/",
      description: "Amazon Webservice Relational Database was used to host our MySQL relational database."
    },
    {
      id: 14,
      name : "AWS Amplify",
      link: "https://docs.amplify.aws/",
      description: "AWS Amplify to host both the frontend website and to handle requests to the backend."
    },
    {
      id: 15,
      name : "Flask",
      link: "https://flask.palletsprojects.com/en/2.2.x/",
      description: "Flask was used to create objects out of model instances so they could easily be inserted into the database and retrieved from it."
    },
    {
      id: 16,
      name : "Flask Marshmallow",
      link: "https://flask-marshmallow.readthedocs.io/en/latest/",
      description: "The Flask extension/library, Marshmallow, facilitates object (de)serialization, allowing for model instances to be transmitted over HTTP."
    },
    {
      id: 17,
      name : "Flask SQLAlchemy",
      link: "https://flask-sqlalchemy.palletsprojects.com/en/3.0.x/",
      description: "Flask SQLAlchemy was used to create objects out of model instances so they could easily be inserted into the database and retrieved from it."
    },
    {
      id: 18,
      name : "PostMan",
      link: "https://www.postman.com/",
      description: "Used for testing third-party API endpoints and developing our own API."
    },
    {
      id: 19,
      name : "BeeKeeper Studio",
      link: "https://www.beekeeperstudio.io/",
      description: "A SQL client that provides a user-friendly interface with which to interact with data in the database."
    }
  ]

export default tools