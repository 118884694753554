import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import soccerBackground from '../assets/images/soccerBackground.jpg'
import teamsPic from '../assets/images/teamspic.jpg'
import playersPic from '../assets/images/playerspic.jpg'
import leaguesPic from '../assets/images/leaguespic-2.jpg'
import './/Home.css'
// import { Leagues, Teams, Players } from '../App.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const Home = () => {
  return (
    <div className='home-page'>
      <div
        className='bg d-flex justify-content-center'
        style={{
          backgroundImage: `url(${soccerBackground})`,
          backgroundSize: 'cover',
          width: '100%',
          height: '100vh',
        }}
      >
        <h3
          style={{
            color: '#ffffff',
            marginTop: '200px',
          }}
        >
          The best place to learn about different leagues, teams and players in{' '}
          <b>
            <i>all of the sports</i>
          </b>
        </h3>
      </div>
      <Container>
        <Row style={{ height: 100 }}> </Row>
        <Row style={{ height: 150 }}>
          <h1 className='header-1'>
            <b>Get the ball rolling...</b>
          </h1>
        </Row>
        <Row>
          <Col>
            <Card style={{ alignItems: 'center' }}>
              <Card.Title className='header-1'>
                <b>Leagues</b>
              </Card.Title>
              <img
                src={leaguesPic}
                alt=""
                className='card-image-top'
                style={{
                  width: '92%',
                }}
              ></img>
              <Card.Body>
                <Button name='href' href='/leagues' className='card-link'>
                  Explore Leagues
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ alignItems: 'center' }}>
              <Card.Title className='header-1'>
                <b>Teams</b>
              </Card.Title>
              <img
                src={teamsPic}
                alt=""
                className='card-image-top'
                style={{
                  width: '100%',
                }}
              ></img>
              <Card.Body>
                <Button name='href' href='/teams' className='card-link'>
                  Explore Teams
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ alignItems: 'center' }}>
              <Card.Title className='header-1'>
                <b>Players</b>
              </Card.Title>
              <img
                src={playersPic}
                alt=""
                className='card-image-top'
                style={{
                  width: '100%',
                }}
              ></img>
              <Card.Body>
                <Button name='href' href='/players' className='card-link'>
                  Explore Players
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home
