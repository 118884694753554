// Inspired by IDB 12 Wine World

import React from 'react';
import Card from 'react-bootstrap/Card';
import { ListGroup } from 'react-bootstrap';

const DevCard = ({
    name,
    bio,
    role,
    image,
    commits,
    issues,
    tests
}) => {
    return (
        <Card style={{ width: '19rem', height: '43rem', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)' }}>
            <Card.Img variant="top" src={image} style={{ height: '50%'}} />
            <Card.Body>
                <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{name}</Card.Title>
                <Card.Text style={{ fontSize: '0.9rem' }}>
                {bio}
                </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroup.Item style={{ backgroundColor: '#ffebe2', fontWeight: 'bold' }}>Role: {role}</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: '#ffebe2', fontWeight: 'bold' }}>Tests: {tests}</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: '#ffebe2', fontWeight: 'bold' }}>Commits: {commits}</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: '#ffebe2', fontWeight: 'bold' }}>Issues: {issues}</ListGroup.Item>
            </ListGroup>
        </Card>
    );
}

export default DevCard;
