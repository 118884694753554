import React from 'react'

// import { Container, Row, Col } from 'react-bootstrap'
import { Container} from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

// Import Components
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const Layout = () => {
  return (
    <Container fluid className='p-0'>
      <Container fluid className='p-0' style={{ minHeight: '100vh' }}>
        <NavBar />
        <Outlet />
      </Container>
      <Footer />
    </Container>
  )
}

export default Layout
