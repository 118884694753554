import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Container } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContentLeague from '../components/TabContentLeague'
import TabContentTeam from '../components/TabContentTeam'
import TabContentPlayer from '../components/TabContentPlayer'

const Search = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const query = queryParameters.get('query')

  const [response, setResponse] = useState(null)

  const getSearchResults = async () => {
    try {
      const res = await axios.get(
        `https://api.allthesports.info/search?query=${query}`
      )
      setResponse(res.data)
    } catch (err) {
      console.log(err)
    }
  }


  const total_leagues = response ? response['leagues'].length : 0
  const total_teams = response ? response['teams'].length : 0
  const total_players = response ? response['players'].length : 0

  const leagues = response ? response['leagues'] : []
  const teams = response ? response['teams'] : []
  const players = response ? response['players'] : []

  const [key, setKey] = useState('leagues')
  
  useEffect(() => {
    getSearchResults()
  })

  return (
    <React.Fragment>
      <Container>
        <h1 className='header-1 mt-4'>Search Results</h1>

        <Tabs
          defaultActiveKey='profile'
          id='justify-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className='mb-3 mt-4'
          justify
        >
          <Tab eventKey='leagues' title='Leagues'>
            <TabContentLeague
              total_instances={total_leagues}
              leagues={leagues}
              keywords={query}
            />
          </Tab>
          <Tab eventKey='teams' title='Teams'>
            <TabContentTeam
              total_instances={total_teams}
              teams={teams} 
              keywords={query}
            />
          </Tab>
          <Tab eventKey='players' title='Players'>
            <TabContentPlayer
              total_instances={total_players}
              players={players}
              keywords={query}
            />
          </Tab>
        </Tabs>
      </Container>
    </React.Fragment>
  )
}

export default Search
