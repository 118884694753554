import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { useEffect, useState } from 'react'

// Simulate fetching team data (actual api only fetches team that matches id)


const TeamInstance = () => {
  let { id } = useParams()

  const [team, setTeam] = useState([])
  const [league, setLeague] = useState([])
  const [sport, setSport] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [var0, setVar0] = useState([])
  const [var1, setVar1] = useState([])
  const [var2, setVar2] = useState([])
  const [var3, setVar3] = useState([])
  const [var4, setVar4] = useState([])

  const [player0, setPlayer0] = useState([])
  const [player1, setPlayer1] = useState([])
  const [player2, setPlayer2] = useState([])
  const [player3, setPlayer3] = useState([])
  const [player4, setPlayer4] = useState([])
  const [player5, setPlayer5] = useState([])
  const [player6, setPlayer6] = useState([])
  const [player7, setPlayer7] = useState([])


  useEffect(() => {
    const loadTeam = async () => {
      axios.get('https://api.allthesports.info/teams/' + id)
        .then((response) => {
          setTeam(response.data)
          console.log(response.data)
          console.log("Asking for team id: " + response.data.league_id)
          console.log("Asking for sport id: " + response.data.sport_id)
          axios.get('https://api.allthesports.info/leagues/' + response.data.league_id)
            .then((response) => {
              setLeague(response.data)
              console.log(response.data)
            })
            .catch((error) => {
              console.error(error)
            })
          axios.get('https://api.allthesports.info/sports/' + response.data.sport_id)
            .then((response) => {
              setSport(response.data)
              console.log(response.data)
            })
            .catch((error) => {
              console.error(error)
            })
          axios.get('https://api.allthesports.info/metrics/' + response.data.id)
            .then((response) => {
              if (response.data.length > 0) {
                setVar0(response.data[0])
                console.log("Var0: " + response.data[0])
              }
              if (response.data.length > 1) {
                setVar1(response.data[1])
                console.log("Var1: " + response.data[1])
              }
              if (response.data.length > 2) {
                setVar2(response.data[2])
                console.log("Var2: " + response.data[2])
              }
              if (response.data.length > 3) {
                setVar3(response.data[3])
                console.log("Var3: " + response.data[3])
              }
              if (response.data.length > 4) {
                setVar4(response.data[4])
                console.log("Var4: " + response.data[4])
              }
              console.log(response.data)
            })
            .catch((error) => {
              console.error(error)
            })

          // Get players using team id
          // Get Players from Team
          axios.get("https://api.allthesports.info/players/team/" + response.data.id)
          .then(response => {
            const randomIndexes = [];

            while (randomIndexes.length < 8) {
              const randomIndex = Math.floor(Math.random() * response.data.length);
              if (!randomIndexes.includes(randomIndex) && response.data[randomIndex].id !== id) {
                randomIndexes.push(randomIndex);
              } 
            }

            // Set players
            setPlayer0(response.data[randomIndexes[0]]);
            setPlayer1(response.data[randomIndexes[1]]);
            setPlayer2(response.data[randomIndexes[2]]);
            setPlayer3(response.data[randomIndexes[3]]);
            setPlayer4(response.data[randomIndexes[4]]);
            setPlayer5(response.data[randomIndexes[5]]);
            setPlayer6(response.data[randomIndexes[6]]);
            setPlayer7(response.data[randomIndexes[7]]);
            
          })
          .catch(error => {
            console.error(error);
          });
          
        })
        .catch((error) => {
          console.error(error)
        })
      setLoaded(true)
    }
    if (!loaded) {
      loadTeam()
    }
  }); 



  return (
    <React.Fragment>
      <Container fluid className='text-center'>
        {/* Refactor next 2 lines. Row/col here only used to create smaller container */}
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            className='border border-2 rounded border-secondary mt-4 p-4'
          >
            <img
              src={team.logo}
              alt={`${team.name}'s logo`}
              className='mb-4 mt-4 pb-4'
            />
            <h1 className='display-6 '>{String(team.name)}</h1>

            <hr />

            {/* Game Stats */}
            <Row className='mt-4'>
              <h3 className='mb-4'>Team Info</h3>
              <Col>
                <Row>
                  <h4>{league.name}</h4>
                </Row>
                <Row> 
                <a href= {"/leagues/" + league.id}>
                <img
                    src={league.logo}
                    alt={`${league.name}'s logo`}
                    // Center this image with the text in the row above
                    style={{ width: "150px", height: "140px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                  </a>
                  <h6>Click on me!</h6>
                </Row>
              </Col>
              <Col>
                <Row>
                <Row>
                  <h4>League Country</h4>
                </Row>
                <Row className='mt-2'>
                  <p className='text-muted' style={{ fontSize: '1.25rem' }}>                   
                  </p>
                </Row>
                <Row className='mt-2'>
                  <p className='text-muted' style={{ fontSize: '1.25rem' }}>                   
                  </p>
                </Row>
                  <h3>
                    {league.country}
                  </h3>
                </Row>
              </Col>
              <Col>
                <Row>
                  <h4>Sport Played</h4>
                </Row>
                <Row className='mt-2'>
                  <p className='text-muted' style={{ fontSize: '1.25rem' }}>                   
                  </p>
                </Row>
                <Row className='mt-2'>
                  <p className='text-muted' style={{ fontSize: '1.25rem' }}>                   
                  </p>
                </Row>
                <Row>
                  <h3>
                    {sport.name}
                  </h3>
                </Row>
              </Col>
            </Row>

            <hr />

            {/* If there is var 0, then show var 0, otherwise show nothing */}
            {var0.name ? (
              <div>
                < Row className='mt-4'>
                <h3 className='mb-4'>Team Metrics</h3>
                <Col>
                  <Row>
                    <h4>{var0.name ? var0.name : "Metric 1" }</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                      {var0.value ? var0.value : "N/A" }
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h4>{var1.name ? var1.name : "Metric 2" }</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                      {var1.value ? var1.value : "N/A" }
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h4>{var2.name ? var2.name : "Metric 3" }</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                      {var2.value ? var2.value : "N/A" }
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h4>{var3.name ? var3.name : "Metric 4" }</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                      {var3.value ? var3.value : "N/A" }
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h4>{var4.name ? var4.name : "Metric 5" }</h4>
                  </Row>
                  <Row>
                    <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                      {var4.value ? var4.value : "N/A" }
                    </p>
                  </Row>
                </Col>
              </Row>
              <hr />
            </div>
            ) : (
              <div></div>
            )}

            
            {/* Misc */}
            <div>
              <Row className='mt-4'>
                {/* Left Lane */}
                { player0.name ? (
                <Col>
                  <Row className='mt-4'>
                    <h3 className='mb-4'>Players</h3>
                    <Col>
                    {player0 ? (
                    <Col key = {player0.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}> 
                        <a href= {"/players/" + player0.id}>
                          <img
                            src={player0.photo}
                            alt={`${player0.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player0.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    {player2 ? (
                    <Col key = {player2.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player2.id}>
                          <img
                            src={player2.photo}
                            alt={`${player2.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player2.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    </Col>
                    <Col>
                    {player4 ? (
                    <Col key = {player4.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player4.id}>
                          <img
                            src={player4.photo}
                            alt={`${player4.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player4.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    {player6 ? (
                    <Col key = {player6.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player6.id}>
                          <img
                            src={player6.photo}
                            alt={`${player6.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player6.name}</h5>
                        </div>
                      </div>
                      </Col>
                      ) : ( <div></div> )}
                    </Col>

                  </Row>
                </Col>
                ) : ( <div></div> )}
            {/* Center Lane */}
                <Col>
                  <h3 className='mb-4'>Other Information</h3>

                  <Row className='mt-4'>
                    <h4>Team Manager</h4>
                  </Row>
                  <Row>
                    <p className='text-muted' style={{ fontSize: '1.25rem' }}>
                      {team.manager_name}
                    </p>
                  </Row>

                  <Row className='mt-2'>
                    <h4>Home Stadium</h4>
                  </Row>
                  <Row>
                    <p className='text-muted' style={{ fontSize: '1.25rem' }}>
                      {team.stadium_name}
                    </p>
                  </Row>

                  <Row className='mt-2'>
                    <h4>Stadium Capacity</h4>
                  </Row>
                  <Row>
                    <p className='text-muted' style={{ fontSize: '1.25rem' }}>
                      {team.stadium_capacity === 0 ? 'N/A' : team.stadium_capacity}
                    </p>
                  </Row>

                  <Row className='mt-2'>
                    <h4>Country</h4>
                  </Row>
                  <Row>
                    <p className='text-muted' style={{ fontSize: '1.25rem' }}>
                      {team.country_name}
                    </p>
                  </Row>
                </Col>
            {/* Right Lane */}
                { player1.name ? (
                <Col>
                  <Row className='mt-4'>
                    <h3 className='mb-4'>Players</h3>
                    { player1.name ? (
                    <Col key = {player1.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player1.id}>
                          <img
                            src={player1.photo}
                            alt={`${player1.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player1.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    { player3 ? (
                    <Col key = {player3.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player3.id}>
                          <img
                            src={player3.photo}
                            alt={`${player3.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player3.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    { player5 ? (
                    <Col key = {player5.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player5.id}>
                          <img
                            src={player5.photo}
                            alt={`${player5.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player5.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                    { player7 ? (
                    <Col key = {player7.id}>
                      <div className="card" style={{ width: "9rem", height: "10rem" }}>
                        <a href= {"/players/" + player7.id}>
                          <img
                            src={player7.photo}
                            alt={`${player7.name}`}
                            className="mx-auto d-block"
                            style={{ width: "65px", height: "65px" }}
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{player7.name}</h5>
                        </div>
                      </div>
                    </Col>
                    ) : ( <div></div> )}
                  </Row>
                </Col>
                ) : ( <div></div> )}
              </Row>
            </div>


            <Row className='mt-2'>
              <h4>Team Location Map</h4>
            </Row>
            
            { team.map_src &&
              <Row>
                <iframe
                  src={team.map_src}
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy='no-referrer-when-downgrade'
                  title='Google Maps for Team Location'
                />
              </Row>
            }

            {/* bootstrap carousel component for images */}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
export default TeamInstance
