import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Plot from 'react-plotly.js'

const getRawData = async () => {
  var restaurants = await axios.get(
    'https://api.nutrinet.me/restaurants?perPage=5000'
  )
  return restaurants['data']['data']
}

const getRatingData = (restaurants, pricepoint) => {
  return restaurants.filter((restaurant) => restaurant.pricepoint == pricepoint)
}

const RestrauntRatingBoxPlot = () => {
  const [restaurants, setRestaurants] = useState([])

  useEffect(() => {
    getRawData().then((data) => {
      const filteredRestaurants = data.filter(
        (restaurant) => restaurant.pricepoint !== 0 && restaurant.rating !== 0
      )
      setRestaurants(filteredRestaurants)
    })
  }, [])

  const data = []
  for (let pricepoint = 1; pricepoint <= 4; pricepoint++) {
    const trace = {
      y: getRatingData(restaurants, pricepoint).map(
        (restaurant) => restaurant.rating
      ),
      type: 'box',
      name: '$'.repeat(pricepoint),
    }
    data.push(trace)
  }

  return (
    <div className='d-flex justify-content-center'>
      <Plot
        data={data}
        layout={{
          margin: {
            t: 10,
          },
          width: 1000,
          height: 600,
          xaxis: {
            title: 'Pricepoint',
          },
          yaxis: {
            title: 'Rating',
          },
        }}
      />
    </div>
  )
}

export default RestrauntRatingBoxPlot
