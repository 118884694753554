import React, { useState } from 'react'
// import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()

  const handleSearch = (event) => {
    event.preventDefault()
    if (searchQuery) {
      navigate(`/search?query=${searchQuery}`)
    }
  }

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value)
  }

  return (
    <form className='d-flex' onSubmit={handleSearch}>
      <input
        type='text'
        className='form-control'
        value={searchQuery}
        onChange={handleInputChange}
        placeholder='Search...'
      />
      <button type='submit' className='btn btn-primary'>
        Submit
      </button>
    </form>
  )
}

export default SearchBar
