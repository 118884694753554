import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector } from 'recharts'
import axios from 'axios'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >{`Recipe(s): ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const data2 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
]

const getRawData = async () => {
  var recipes = await axios.get('https://api.nutrinet.me/recipes?perPage=5000')
  return recipes['data']['data']
}

const RecipeNationalityPieChart = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    getRawData().then((data) => setRecipes(data))
  }, [])

  const cuisineFreq = []
  recipes.forEach((recipe) => {
    const cuisine = recipe.cuisines[0]
    if (cuisine in cuisineFreq) {
      cuisineFreq[cuisine]++
    } else {
      cuisineFreq[cuisine] = 1
    }
  })

  const data = []
  Object.keys(cuisineFreq).forEach((cuisine) => {
    data.push({ name: cuisine, value: cuisineFreq[cuisine] })
  })

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  return (
    <div className='d-flex justify-content-center'>
      <PieChart width={1000} height={600}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx='50%'
          cy='50%'
          innerRadius={170}
          outerRadius={240}
          fill='#8884d8'
          dataKey='value'
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </div>
  )
}

export default RecipeNationalityPieChart
