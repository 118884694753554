import React, {useEffect, useState, useRef} from 'react'
// import { useLocation } from 'react-router-dom'
import {Container, Row, Col, Spinner, Pagination, Button, Form } from 'react-bootstrap';
import FilterDropdown from '../components/FilterDropdown';
import axios from 'axios'
import InstanceCard from '../components/InstanceCardPlayer'

import './Models.css'

const Players = () => {
  // const location = useLocation()
  const [players, setPlayers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); /* Inspired from IDB Group 6 */
  const sports_id = ['', 'Football', 'Tennis', 'Basketball', 'Ice Hockey', 'Volleyball', 'Handball'];
  const [sort, setSort] = useState('');
  const [sport, setSport] = useState('');
  const [sports, setSports] = useState([]);
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([])
  const [photo, setPhoto] = useState('');
  const [biography, setBiography] = useState('');
  const [video, setVideo] = useState('');
  const search = useRef("");

  const handleSort = (event) => {
    resetDropdowns();
    setSort(event);
    setLoaded(false);
  };

  const handleSportFilter = (event) => {
    resetDropdowns();
    setSport(event); 
    setLoaded(false);
  };

  const handleCountryFilter = (event) => {
    resetDropdowns();
    setCountry(event);
    setLoaded(false);
  };
  
  const handlePhotoFilter = (event) => {
    resetDropdowns();
    setPhoto(event);
    setLoaded(false);
  };
  
  const handleBiographyFilter = (event) => {
    resetDropdowns();
    setBiography(event);
    setLoaded(false);
  };

  const handleVideoFilter = (event) => {
    resetDropdowns();
    setVideo(event);
    setLoaded(false);
  };

  function resetDropdowns() {
    search.current.value = ""
    setSort("");
    setSport("");
    setCountry("");
    setPhoto("");
    setBiography("");
    setVideo("");
  }

  function changePage(pageNum) {
    setCurrentPage(pageNum);
    setLoaded(false);
  }

  function highlight(info) {
    if (search.current.value !== "" && info != null){
      info = info.toString()
      const words = search.current.value.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }

  useEffect(() => {
    var totalResponse;
    const loadPlayers = async () => {
      try {
        var response = null;
        console.log("Search value: " + search.current.value)
        if (search.current.value !== ""){
          response = (await axios.get("https://api.allthesports.info/players/search", {
            params: {
              terms: search.current.value,
              offset: currentPage,
              limit: 150
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/players/search", {
            params: {
              terms: search.current.value
            }
          })).data.length;
          console.log("Search response: ", response)
        } else if (sort !== ''){
          response = await axios.get("https://api.allthesports.info/players/sort", {
            params: {
              sort_type: sort,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/sort", {
            params: {
              sort_type: sort
            }
          })).data.length;
        } else if (sport !== ''){
          response = await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "sport",
              filter_value: sport,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "sport",
              filter_value: sport
            }
          })).data.length;
        } else if (country !== ''){
          response = await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "country",
              filter_value: country,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "country",
              filter_value: country
            }
          })).data.length;
        } else if (photo !== ''){
          response = await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "photo",
              filter_value: photo,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "photo",
              filter_value: photo
            }
          })).data.length;
        } else if (biography !== ''){
          response = await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "biography",
              filter_value: biography,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "biography",
              filter_value: biography
            }
          })).data.length;
        } else if (video !== ''){
          response = await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "video",
              filter_value: video,
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/players/filter", {
            params: {
              filter_type: "video",
              filter_value: video
            }
          })).data.length;
        } else {
          response = await axios.get("https://api.allthesports.info/players", {
            params: {
              offset: currentPage,
              limit: 150
            }
          });
          totalResponse = (await axios.get("https://api.allthesports.info/total_players")).data;
        }

        // Fill player sports filter list
        if (sports.length === 0){
          var temp_sports = await axios.get("https://api.allthesports.info/sports", {
            params: {
              offset: currentPage,
              limit: 150
            }
          });
          setSports(temp_sports.data.map(sport => sport.name).filter(sport => sport !== 'Tennis'));
        }

        // Fill player countries filter list
        if (countries.length === 0){
          var temp_countries= await axios.get("https://api.allthesports.info/teams", {
            params: {
              offset: currentPage,
              limit: 150
            }
          });
          var temp_countries_list = temp_countries.data.map(team => team).filter(team => team.sport_id !== 2)
          temp_countries_list = [...new Set(temp_countries_list.map(team => team.country_name))]
          setCountries(temp_countries_list)
        }

        
        const teamPromises = response.data.map(player => {
          return axios.get("https://api.allthesports.info/teams/" + player.team_id)
        });
        const leaguePromises = response.data.map(player => {
          return axios.get("https://api.allthesports.info/leagues/" + player.league_id)
        });
  
        const teamResponses = await Promise.all(teamPromises);
        const leagueResponses = await Promise.all(leaguePromises);
  
        const updatePlayers = response.data.map((player, index) => {
          return{
            ...player,
            team_name: teamResponses[index].data.name,
            league_name: leagueResponses[index].data.name,
            team_logo: teamResponses[index].data.logo,
            league_logo: leagueResponses[index].data.logo,
          }
        });
  
        setPlayers(updatePlayers);
        console.log("Response: ");
        console.log(updatePlayers);
        
        setTotal(totalResponse);
  
        setLoaded(true);
      } catch (error) {console.error(error);}
    }


    if (!loaded) {
      loadPlayers();
    }
  }, [currentPage, sort, sport, country, photo, biography, video, search.current.value, countries.length, loaded, sports.length]);

  const numPages = (total % 150 === 0) ? total / 150 : Math.floor(total / 150) + 1;
  const pages = [];
  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <Pagination.Item key={i} onClick={() => changePage(i)}>
        {i}
      </Pagination.Item>
    );
  }

  return (
    <React.Fragment>
      <Container>
      <h1 className="header-1" style={{ fontSize: '3rem',  fontWeight: 'bold', borderBottom: "3px solid #b5b5b5", marginBottom: "20px", marginTop: "10px"}}>Players</h1>      
        <Form onSubmit={(event) => {event.preventDefault();setLoaded(false);}}>
          <Row>

            <br />
            <Form.Control ref={search} type="search" placeholder="Search for a Player" className="mr-2" aria-label="Search"
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#fff3ee';
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#FFFFFF';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
                style={{ transition: 'background-color 0.2s ease-in-out, transform 0.3s',
                  transform: 'scale(1)', backgroundColor: '#fff'
                }} 
              />
            <Button variant="outline-success" onClick={() => setLoaded(false)}>Search</Button>
            </Row>
            <br />

            <Row>
              <Col>
                <FilterDropdown title="Sort" items={["Age", "Weight", "Height", "Name", "Team Name"]} onChange={handleSort} color="info" />
              </Col>
              <Col>
                <FilterDropdown title="Sport" items={sports} onChange={handleSportFilter}/>
              </Col>
              <Col>
                <FilterDropdown title="Country" items={countries} onChange={handleCountryFilter} />
              </Col>
              <Col>
                <FilterDropdown title ="Photo" items={["Yes", "No"]} onChange={handlePhotoFilter}/>
              </Col>
              <Col>
                <FilterDropdown title="Biography" items={["Yes", "No"]} onChange={handleBiographyFilter} />
              </Col>
              <Col>
                <FilterDropdown title="Brief Video" items={["Yes", "No"]} onChange={handleVideoFilter} />
              </Col>
              <Col>
                  <Button variant="outline-danger" onClick={() => {resetDropdowns();setLoaded();}}>Clear Filters</Button>
              </Col>
            </Row>
            <br />
            <Row> 
          <hr className='line-1' />           
          </Row>
        </Form>

          <h5 className = "header-2">Here are {total} Players to look at!</h5>
          <hr className='line-1'/>
          <p>
            Page {currentPage} of {numPages}
          </p>
          <Pagination className="justify-content-center" >{pages}</Pagination>
          <p className='lead text-center mt-2 mb-4'> 
          Showing {1 + ((currentPage-1)*150)} to {(players.length + ((currentPage-1)*150))} of {total} results
          </p>

        <Container className='mt-4'>
          <Row className='gap-4'>
            { loaded ? (
            players.map((player) => {
              return (
                <InstanceCard
                  key={player.id}
                  id={player.id}
                  title={player.name}
                  btn_prompt='View Player'
                  card_image={player.photo}
                  age = {highlight(player.age)}
                  weight = {highlight(player.weight)}
                  height = {highlight(player.height)}
                  sport = {highlight(sports_id[player.sport_id])}
                  team = {highlight(player.team_name)}
                  team_logo = {player.team_logo}
                  league = {highlight(player.league_name)}
                  league_logo = {player.league_logo}
                />
              )
            })
            ) :  <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" size = "10rem" />
          </div>}
          </Row>
        </Container>
        <Pagination className="justify-content-center" >{pages}</Pagination>

      </Container>
    </React.Fragment>
  )
}

export default Players