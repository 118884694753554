import React, { useEffect, useState, useRef } from 'react';
// import { useLocation, Link, Route, Routes } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import axios from 'axios';
// import {Container, Row, Col, Spinner, Pagination, Button, Form } from 'react-bootstrap';
import {Container, Row, Col, Pagination, Button, Form } from 'react-bootstrap';
import FilterDropdown from '../components/FilterDropdown';
import InstanceCard from '../components/InstanceCardLeague';
import './/Models.css';

const Leagues = () => {
  // const location = useLocation();
  const [leagues, setLeagues] = useState([]);
  const [sport, setSport] = useState([]);
  const sports_id = ['','Football', 'Tennis', 'Basketball', 'Ice Hockey', 'Volleyball', 'Handball'];
  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState('');
  const [country, setCountry] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numSeasons, setNumSeasons] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const search = useRef("");

  const handleSort = (event) => {
    resetDropdowns();
    setSort(event);
    setLoaded(false);
  };

  const handleCountryFilter = (event) => {
    resetDropdowns();
    setCountry(event);
    setLoaded(false);
  };

  const handleSportFilter = (event) => { 
    resetDropdowns();
    setSport(event);
    setLoaded(false);
  };

  const handleStartDateFilter = (event) => {
    resetDropdowns();
    setStartDate(event);
    setLoaded(false);
  };

  const handleEndDateFilter = (event) => {
    resetDropdowns();
    setEndDate(event);
    setLoaded(false);
  };

  const handleNumSeasonsFilter = (event) => {
    resetDropdowns();
    setNumSeasons(event);
    setLoaded(false);
  };

  function resetDropdowns() {
    search.current.value = ""
    setSort("");
    setSport("");
    setCountry("");
    setStartDate("");
    setEndDate("");
    setNumSeasons("");
  }

  function changePage(pageNum) {
    setCurrentPage(pageNum);
    setLoaded(false);
  }

  function highlight(info) {
    if (search.current.value !== "" && info != null){
      info = info.toString()
      const words = search.current.value.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }

  useEffect(() => {
    var totalResponse;
    const loadLeagues = async () => {
      try {
        var response = null;
        if (search.current.value !== "") {        
          const intermediate = await axios.get("https://api.allthesports.info//leagues/search", {
            params: {
              terms: search.current.value
            }
          });
          response = intermediate.data;
          setTotal(intermediate.data.length);
        } else if (sort !== "") {  
          response = await axios.get("https://api.allthesports.info//leagues/sort", {
            params: {
              sort_type: sort,
              offset: currentPage,
              limit: 20
            }
          });
          response = response.data;
          totalResponse = await axios.get("https://api.allthesports.info//leagues/sort", {
            params: {
              sort_type: sort,
              offset: 0,
              limit: 100000
            }
          });
          setTotal(totalResponse.data.length);
        } else if (sport !== '' && !Array.isArray(sport)) {
          response = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "Sport",
              filter_value: sport,
              offset: currentPage,
              limit: 20
            }
          });
          response = response.data;
          setTotal(response.length);
        } else if (country !== "") {         
          response = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "Country",
              filter_value: country,
              offset: currentPage,
              limit: 20
            }
          });
          response = response.data;
          setTotal(response.length);
        } else if (startDate !== "") {
          let startYear = 0;
          if (startDate === "This Year") {
            startYear = 2023;
          } else if (startDate === "Last Year") {
            startYear = 2022;
          }
          response = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "Start Date",
              filter_value: startYear,
              offset: currentPage,
              limit: 20
            }
          });

          response = response.data;
          setTotal(response.length);

        } else if (endDate !== "") {

          let endYear = 0;
          if (endDate === "This Year") {
            endYear = 2023;
          } else if (endDate === "Last Year") {
            endYear = 2022;
          }

          response = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "End Date",
              filter_value: endYear,
              offset: currentPage,
              limit: 20
            }
          });
          response = response.data;
          setTotal(response.length);

        } else if (numSeasons !== "") {
          response = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "map",
              filter_value: numSeasons,
              offset: currentPage,
              limit: 20
            }
          });
          response = response.data;
          totalResponse = await axios.get("https://api.allthesports.info//leagues/filter", {
            params: {
              filter_type: "map",
              filter_value: numSeasons,
              offset: 0,
              limit: 100000
            }
          });
          setTotal(totalResponse.data.length);
        } else {         
        const intermediate = await axios.get('https://api.allthesports.info/leagues',
          { params: {
              offset: currentPage,
              limit: 20},
          });
          response = intermediate.data;
          const total = await axios.get('https://api.allthesports.info/total_leagues');
          setTotal(total.data);
        }


        console.log("Response: ", response);
        const sportPromises = response.map((league) => axios.get(`https://api.allthesports.info/sports/${league.sport_id}`));
        const sportResponses = await Promise.all(sportPromises);

        const factPromises = response.map((league) => axios.get(`https://api.allthesports.info/facts/${league.id}`));
        const factResponses = await Promise.all(factPromises);

        const updatedLeagues = response.map((league, index) => {
        const startDate = new Date(league.start_date);
        const endDate = new Date(league.end_date);

          return {
            ...league,
            sport: sportResponses[index].data.name,
            fact_count: factResponses[index].data.length,
            formattedStartDate: `${startDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })} on ${startDate.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}`,
            formattedEndDate: `${endDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })} on ${endDate.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}`,
          };
        });

        setLeagues(updatedLeagues);
        setLoaded(true);
      } catch (error) {console.error(error);}
    };

    if (!loaded) {
      loadLeagues();
    }
  },[currentPage, sort, sport, country, startDate, endDate, numSeasons, search.current.value, loaded]);

  
  const numPages = ((total % 20 === 0) ? total / 20 : Math.floor(total / 20) + 1) ? (total % 20 === 0) ? total / 20 : Math.floor(total / 20) + 1 : 1;
  const pages = [];
  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <Pagination.Item key={i} onClick={() => changePage(i)}>
        {i}
      </Pagination.Item>
    );
  }


  return (
    <React.Fragment>
      <Container>
        <h1 className="header-1" style={{ fontSize: '3rem',  fontWeight: 'bold', borderBottom: "3px solid #b5b5b5", marginBottom: "20px", marginTop: "10px"}}>Leagues</h1>
          <Form onSubmit={(event) => {event.preventDefault();setLoaded(false);}}>
            <Row>
              <br />
              <Form.Control ref={search} type="search" placeholder="Search for a League" className="mr-2" aria-label="Search"
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#fff3ee';
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#FFFFFF';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
                style={{ transition: 'background-color 0.2s ease-in-out, transform 0.3s',
                  transform: 'scale(1)', backgroundColor: '#fff'
                }} 
              />

                <Button variant="outline-success" onClick={() => { setLoaded(false); }}>Search</Button>
              </Row>
              <br />

              <Row className="justify-content-between">
                <Col>
                  <FilterDropdown title="Sort" items={["Name", "emaN", "Country", "Start Date", "End Date", "Sport"]} onChange={handleSort} color="info" />
                </Col>
                <Col>
                  <FilterDropdown title="Sport" items={["Football", "Tennis", "Basketball", "Ice Hockey", "Volleyball", "Handball"]} onChange={handleSportFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="Country" items={["Australia", "Northern Ireland", "Poland", "Portugal", "Romania", "Russia", "Serbia", "Spain", "Sweden", "Switzerland", "USA", "Wales"]} onChange={handleCountryFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="League Start" items={["Years Ago", "Last Year", "This Year"]} onChange={handleStartDateFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="League End" items={["Years Ago", "Last Year", "This Year"]} onChange={handleEndDateFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="Map" items={["Yes", "No"]} onChange={handleNumSeasonsFilter} />
                </Col>
                <Col>
                  <Button variant="outline-danger" onClick={() => {resetDropdowns();setLoaded();}}>Clear Filters</Button>
                </Col>
              </Row>
              <br />
              <Row> 
            <hr className='line-1' />           
            </Row>
          </Form>

          <h5 className = "header-2">Here are {total} Leagues to look at!</h5>
          <hr className='line-1'/>
          <p>
            Page {currentPage} of {numPages}
          </p>
          <Pagination className="justify-content-center">{pages}</Pagination>
          <p className='lead text-center mt-2 mb-4'> 
          Showing {1 + ((currentPage-1)*20)} to {(leagues.length + ((currentPage-1)*20))} of {total} results
          </p>
          
        <Container className='mt-4'>
          <Row className='gap-4'>
              { loaded ? (
              leagues.map((league) => {
                return (
                  <InstanceCard
                    key={league.id}
                    id={league.id}
                    title={highlight(league.name)}
                    League_Country = {highlight(league.country  != 'No information about host coun' ? league.country : 'No official host country')}
                    League_Sport = {highlight(sports_id[league.sport_id])}
                    League_Start_Date = {highlight(league.formattedStartDate)}
                    League_End_Date = {highlight(league.formattedEndDate)}
                    League_Fact_Count = {highlight(league.fact_count ? league.fact_count : 0 + " ):")}
                    btn_prompt='League Details'
                    card_image={league.logo ? league.logo : 'https://www.thesportsdb.com/images/media/league/badge/2x2vqp1448816368.png'}

                  />
                )
              })
              ) : (
                <p>Loading...</p>
              )}
          </Row>
          <Pagination className="justify-content-center">{pages}</Pagination>
        </Container>
      </Container>
    </React.Fragment>
  )
}

export default Leagues
