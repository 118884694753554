import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts'

import axios from 'axios'

const ROUND_FACTOR = 1
var binWidth = 0

const getRawData = async () => {
  var players = await axios.get('https://api.allthesports.info/players')
  return players.data
}

const roundNum = (num, ROUND_FACTOR) => {
  return Math.ceil(num / ROUND_FACTOR) * ROUND_FACTOR
}

const generateBins = (maxAge, binCount) => {
  binWidth = roundNum(maxAge / binCount, ROUND_FACTOR)
  const maxBin = roundNum(maxAge, ROUND_FACTOR)

  var bins = []
  for (let i = 0; i < maxBin; i += binWidth) {
    bins = [...bins, i]
  }
  console.log('bins: ', bins)
  return bins
}

const getAgeBinData = async (binCount) => {
  const rawPlayers = Array.from(await getRawData())

  var maxAge = 0
  rawPlayers.forEach((player) => {
    maxAge = Math.max(maxAge, player.age)
  })

  const binWidth = roundNum(maxAge / binCount, ROUND_FACTOR)
  const bins = generateBins(maxAge, binCount)

  const ageBinData = {}
  bins.forEach((bin) => {
    ageBinData[bin] = 0
  })

  rawPlayers.forEach((player) => {
    if (player.age > 0) {
      const bin = Math.floor(player.age / binWidth) * binWidth
      ageBinData[bin]++
    }
  })

  console.log(ageBinData)
  return ageBinData
}

const PlayerAgeHistogram = () => {
  const [formattedBinData, setFormattedBinData] = useState([])

  useEffect(() => {
    const rawBinData = getAgeBinData(20)

    const formattedBinData = []
    rawBinData.then((bins) => {
      Object.keys(bins).forEach((bin) => {
        formattedBinData.push({
          Name: String(bin) + ' - ' + String(Number(bin) + binWidth),
          Players: bins[bin],
        })

        setFormattedBinData(formattedBinData)
      })
    })
  }, [])

  return (
    <div className='d-flex justify-content-center'>
      <BarChart
        width={1200}
        height={500}
        data={formattedBinData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='Name'>
          <Label value='Age' offset={-20} position='insideBottom' />
        </XAxis>
        <YAxis>
          <Label
            value='Number of Players'
            angle={270}
            position='left'
            offset={10}
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip />
        <Bar dataKey='Players' fill='#82ca9d' />
      </BarChart>
    </div>
  )
}

export default PlayerAgeHistogram
