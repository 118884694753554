import React from 'react'

const Footer = () => {
  return (
    <footer
      className='mt-4 py-4 text-center'
      style={{
        background: '#24292e',
        color: '#d3d3d3',
        width: '100%',
      }}
    >
      &copy; AllTheSports 2023 | Check us on <a href="https://gitlab.com/dbjeng/cs373-idb-1" style={{color: '#d3d3d3'}}>Gitlab</a>
    </footer>
  )
}

export default Footer
