import React from 'react'
import InstanceCard from './InstanceCardPlayer'
import { Container, Row, Spinner } from 'react-bootstrap'

const TabContentPlayer = ({ total_instances, players, keywords }) => {
  const sports_id = [
    '',
    'Football',
    'Tennis',
    'Basketball',
    'Ice Hockey',
    'Volleyball',
    'Handball',
  ]

  function highlight(info) {
    if (info != null){
      info = info.toString()
      const words = keywords.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }

  return (
    <React.Fragment>
      <h5 className='header-2'>
        Here are {total_instances} players to look at!
      </h5>

      <Container className='mt-4'>
        <Row className='gap-4'>
          {players ? (
            players.map((player) => {
              return (
                <InstanceCard
                  key={player.id}
                  id={player.id}
                  title={player.name}
                  btn_prompt='View Player'
                  card_image={player.photo}
                  age = {highlight(player.age)}
                  weight = {highlight(player.weight)}
                  height = {highlight(player.height)}
                  sport = {highlight(sports_id[player.sport_id])}
                  team = {highlight(player.team_name)}
                  team_logo = {player.team_logo}
                  league = {highlight(player.league_name)}
                  league_logo = {player.league_logo}
                />
              )
            })
          ) : (
            <div className='d-flex justify-content-center'>
              <Spinner animation='border' variant='primary' size='10rem' />
            </div>
          )}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default TabContentPlayer
