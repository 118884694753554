// Inspired by IDB 12 Wine World
import { postman } from "../assets/images/index.js"
import { gitlab } from "../assets/images/index.js"
import { thesportsdb } from "../assets/images/index.js"
import { sportsscore } from "../assets/images/index.js"
import { youtube } from "../assets/images/index.js"
import { googlemaps } from "../assets/images/index.js"


const ApiCardData = [
    {
        name : "Postman API",
        description : "Postman simplifies API development and collaboration, enabling faster and better API creation",
        url : "https://documenter.getpostman.com/view/26154442/2s93RQTtzb",
        image : postman
    },
    {
        name : "Gitlab API",
        description : "API for our GitLab statistics, mainly used for the about page",
        url : "https://gitlab.com/api/v4/projects/43420803",
        image : gitlab
    },
    {
        name : "TheSportDB API",
        description : "Used for gathering specific player data",
        url : "https://www.thesportsdb.com/",
        image : thesportsdb
    },
    {
        name : "Sports Score API",
        description : "Used for gathering general league, team and player data",
        url : "https://rapidapi.com/tipsters/api/sportscore1",
        image : sportsscore
    },
    {
        name : "YouTube API",
        description : "Used to get videos for different players",
        url : "https://developers.google.com/youtube/v3",
        image : youtube
    },
    {
        name : "Google Maps Embed API",
        description : "Used to embed maps to stadiums",
        url : "https://developers.google.com/maps/documentation/embed/get-started",
        image : googlemaps
    }
]

export default ApiCardData