import React from 'react'
import InstanceCard from './InstanceCardLeague'
import { Container, Row, Spinner } from 'react-bootstrap'

const sports_id = ['','Football', 'Tennis', 'Basketball', 'Ice Hockey', 'Volleyball', 'Handball'];

const TabContentLeague = ({ total_instances, leagues, keywords }) => {
  function highlight(info) {
    if (info !== null){
      info = info.toString()
      const words = keywords.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }

  return (
    <React.Fragment>
      <h5 className='header-2'>
        Here are {total_instances} leagues to look at!
      </h5>

      <Container className='mt-4'>
        <Row className='gap-4'>
          {leagues ? (
            leagues.map((league) => (
              <InstanceCard
                key={league.id}
                id={league.id}
                title={highlight(league.name)}
                //  == 'No information about host coun'
                League_Country = {highlight(league.country  !== 'No information about host coun' ? 'Host country: ' + league.country : 'No official host country')}
                League_Sport = {highlight('Sport: '+ sports_id[league.sport_id])}
                League_Start_Date = {highlight(league.formattedStartDate)}
                League_End_Date = {highlight(league.formattedEndDate)}
                League_Fact_Count = {highlight(league.fact_count ? league.fact_count : 0 + " ):")}
                btn_prompt='League Details'
                card_image={league.logo ? league.logo : 'https://www.thesportsdb.com/images/media/league/badge/2x2vqp1448816368.png'}
              />
            ))
          ) : (
            <div className='d-flex justify-content-center'>
              <Spinner animation='border' variant='primary' size='10rem' />
            </div>
          )}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default TabContentLeague
