import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import {aaronpic, harmanpic, harmanpic2, bradypic, jeromepic, danielpic} from '../assets/images/index'

const InstanceCard = ({
  id,
  card_image,
  title,
  age,
  weight,
  height,
  sport,
  team,
  league,
}) => {
  const location = useLocation()
  const images = [aaronpic, harmanpic, harmanpic2, bradypic, jeromepic, danielpic]
  let hasNoPhoto = false;
  if (card_image === 'https://tipsscore.com/resb/no-photo.png') {
    card_image = images[Math.floor(Math.random() * (images.length - 1)) + 1];
    hasNoPhoto = true;
  }



  return (
  <React.Fragment>

    <Card 
        style={{ 
          width: '15rem', 
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', 
          cursor: 'pointer', 
          transition: 'background-color 0.3s ease-in-out, transform 0.3s', 
          backgroundColor: '#fff3ee',
          transform: 'scale(1)'
        }} 
        onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#FFA07A'; e.currentTarget.style.transform = 'scale(0.95)' }}
        onMouseOut={(e) => { e.currentTarget.style.backgroundColor = '#fff3ee'; e.currentTarget.style.transform = 'scale(1)' }}
        onClick={() => window.location.href=`/players/${id}`}
      >
      <div className='row align-items-center'>
            <Card.Title style={{ marginBottom: 0 }}>
              <b>{title}</b>
              <p style={{ fontSize: '15px' }}>
              ✭<b>{team}</b>✭
              </p>
            </Card.Title>
      </div>
      <Card.Img src={card_image} />
      {hasNoPhoto && (
      <Card.Text style={{ margin: '0.1rem 0', fontSize: '15Px' }}>
        PLACEHOLDER IMAGE
      </Card.Text>
      )}
      <Card.Body className='text-center d-flex flex-column'>
        <Card.Text style={{ margin: '0.1rem 0' }}>
        <p style={{ fontSize: '15px', marginBottom: 0 }}>
            At <b> {age} years old,</b>
          </p>
        </Card.Text>
        <Card.Text style={{ margin: '0.1rem 0' }}>
        <p style={{ fontSize: '15px', marginBottom: 0 }}>
            weighing in at <b>{weight} KG</b>, at <b>{height} M</b> tall,
          </p>
        </Card.Text>
        <Card.Text style={{ margin: '0.1rem 0' }}>
          <p style={{ fontSize: '15px', marginBottom: 0 }}>
          <b>{title}</b> plays <b>{sport}</b> in the <b>{league}</b>.
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  </React.Fragment>
  )
}

export default InstanceCard
