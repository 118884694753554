// Inspired by IDB 12 Wine World

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// const ApiCard = props => {
    // const { name, description, url, image } = props.api;
const ApiCard = ({
    name,
    description,
    url,
    image,
}) => {
    return (
        <Card style={{ width: '15rem', height: '25rem', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)'}}>
            <Card.Img style={{ height: '70%' }} variant="top" src={image} />
            <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70%', textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card.Title style={{ marginBottom: '0.5rem' }}><strong>{name}</strong></Card.Title>
                <Card.Text style={{ marginBottom: '1rem', fontSize: '15px' }}>{description}</Card.Text>
                </div>
                <div style={{ alignSelf: 'center' }}>
                <a href={url}>
                    <Button style={{ color: '#ffebe2'}}>{name === 'Postman API' ? 'Our API' : 'Learn More'}</Button>
                </a>
                </div>
            </Card.Body>
        </Card>


    );
}

export default ApiCard;