import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts'

import axios from 'axios'

const getData = async () => {
  var leagues = await axios.get('https://api.allthesports.info/leagues')
  var leaguesPerCountry = {}
  leagues.data.forEach((league) => {
    if (league.country != 'No information about host coun') {
      if (league.country in leaguesPerCountry) {
        leaguesPerCountry[league.country]++
      } else {
        leaguesPerCountry[league.country] = 1
      }
    }
  })

  var data = Object.keys(leaguesPerCountry).map((country) => {
    return { 'Name': country, 'Leagues': leaguesPerCountry[country] }
  })

  return data
}

const LeagueCountryBarChart = () => {
  const [data, setData] = useState({})

  useEffect(() => {
    getData().then((chartData) => {
      setData(chartData)
    })
  }, [])

  return (
    <div className='d-flex justify-content-center'>
      <BarChart
        width={1200}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='Name' tick={{ fontSize: 12 }}>
          <Label value='Countries' offset={-20} position='insideBottom' />
        </XAxis>
        <YAxis>
          <Label
            value='Number of Leagues'
            angle={270}
            position='left'
            offset={10}
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip />
        <Bar dataKey='Leagues' fill='#82ca9d' />
      </BarChart>
    </div>
  )
}

export default LeagueCountryBarChart
