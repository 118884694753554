import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import {genteam} from '../assets/images/index'

const InstanceCard = ({
  id,
  card_image,
  title,
  country,
  sport,
  league,
  stadium_capacity,
  stadium_name,
  manager_name,
}) => {
  const location = useLocation()

  if (card_image === 'https://tipsscore.com/resb/no-league.png') {
    card_image = genteam
  }

  return (
    <React.Fragment>
      <Card 
        style={{ 
          width: '15rem', 
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', 
          cursor: 'pointer', 
          transition: 'background-color 0.3s ease-in-out, transform 0.3s', 
          backgroundColor: '#fff3ee',
          transform: 'scale(1)'
        }} 
        onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#FFA07A'; e.currentTarget.style.transform = 'scale(0.95)' }}
        onMouseOut={(e) => { e.currentTarget.style.backgroundColor = '#fff3ee'; e.currentTarget.style.transform = 'scale(1)' }}
        onClick={() => window.location.href=`/teams/${id}`}
      >
        <Card.Body className='text-center'>
          <div className='row align-items-center'>
            <Card.Title className='row align-items-center'>
              <b>{title}</b>
              <p style={{ fontSize: '15px' }}>
              ✭<b>{country}</b>✭
              </p>
            </Card.Title>
          </div>
          <Card.Img src={card_image} />
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p className='fs-6 mb-1'>
              A(n) <b>{sport} </b> team in the <b>{league}</b> league
            </p>
          </Card.Text>
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p className='fs-6 mb-1'>
              Their home stadium, <b>{stadium_name} </b>, has a <b> capacity of {stadium_capacity}</b> They are managed by <b>{manager_name}</b>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
      <style jsx>{`
        .card-text p {
          margin-bottom: 0.25rem;
        }
      `}</style>
    </React.Fragment>
  )
}

export default InstanceCard
