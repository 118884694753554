import React from 'react'
import InstanceCard from './InstanceCardTeam'
import { Container, Row, Spinner } from 'react-bootstrap'

const TabContentTeam = ({ total_instances, teams, keywords }) => {  
  const sports_id = ['','Football', 'Tennis', 'Basketball', 'Ice Hockey', 'Volleyball', 'Handball'];

  function highlight(info) {
    if (info != null){
      info = info.toString()
      const words = keywords.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }
  
  return (
    <React.Fragment>
      <h5 className='header-2'>Here are {total_instances} teams to look at!</h5>

      <Container className='mt-4'>
        <Row className='gap-4'>
          {teams ? (
            teams.map((team) => (
              <InstanceCard
                key={team.id}
                id={team.id}
                card_image={team.logo}
                title={highlight(team.name)}
                country={highlight(team.country_name)}
                league={highlight(team.league)}
                sport={highlight(sports_id[team.sport_id])}
                stadium_capacity={highlight(team.stadium_capacity || "-")}
                stadium_name={highlight(team.stadium_name || "No Home Stadium")}
                manager_name={highlight(team.manager_name || "No Manager")}
                btn_prompt="View Team"
              />
            ))
          ) : (
            <div className='d-flex justify-content-center'>
              <Spinner animation='border' variant='primary' size='10rem' />
            </div>
          )}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default TabContentTeam
