import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts'

import axios from 'axios'

const ROUND_FACTOR = 100
const CALORIE_LIMIT = 1000

const getRawData = async () => {
  var ingredients = await axios.get(
    'https://api.nutrinet.me/ingredients?perPage=5000'
  )
  return ingredients
}

const roundNum = (num, ROUND_FACTOR) => {
  return Math.ceil(num / ROUND_FACTOR) * ROUND_FACTOR
}

const generateBins = (maxCalorie, binCount) => {
  const binWidth = roundNum(maxCalorie / binCount, ROUND_FACTOR)
  const maxBin = roundNum(maxCalorie, ROUND_FACTOR)

  var bins = []
  for (let i = 0; i < maxBin; i += binWidth) {
    bins = [...bins, i]
  }

  return bins
}

const getCalorieBinData = async (binCount) => {
  const rawIngredients = await getRawData()
  var ingredients = rawIngredients['data']['data']

  ingredients = ingredients.filter(
    (ingredient) => ingredient.calories < CALORIE_LIMIT
  )
  console.log('ingredients:', ingredients)

  var maxCalorie = 0
  ingredients.forEach((ingredient) => {
    maxCalorie = Math.max(maxCalorie, ingredient.calories)
  })

  const binWidth = roundNum(maxCalorie / binCount, ROUND_FACTOR)
  const bins = generateBins(maxCalorie, binCount)

  const calorieBinData = {}
  bins.forEach((bin) => {
    calorieBinData[bin] = 0
  })

  ingredients.forEach((ingredient) => {
    const bin = Math.floor(ingredient.calories / binWidth) * binWidth
    calorieBinData[bin]++
  })

  return calorieBinData
}

const IngredientsCaloriesHistogram = () => {
  const [formattedBinData, setFormattedBinData] = useState([])

  useEffect(() => {
    const binWidth = 100 // Hard coded for now
    const rawBinData = getCalorieBinData(10)

    const formattedBinData = []
    rawBinData.then((bins) => {
      Object.keys(bins).forEach((bin) => {
        formattedBinData.push({
          Name: String(bin) + ' - ' + String(Number(bin) + binWidth),
          Ingredients: bins[bin],
        })

        setFormattedBinData(formattedBinData)
      })
    })
  }, [])

  return (
    <div className='d-flex justify-content-center'>
      <BarChart
        width={1200}
        height={500}
        data={formattedBinData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='Name'>
          <Label
            value='Calories in Ingredient'
            offset={-20}
            position='insideBottom'
          />
        </XAxis>
        <YAxis>
          <Label
            value='Number of Ingredients'
            angle={270}
            position='left'
            offset={10}
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip />
        <Bar dataKey='Ingredients' fill='#82ca9d' />
      </BarChart>
    </div>
  )
}

export default IngredientsCaloriesHistogram
