import React, {useEffect, useState, useRef} from 'react'
import { useLocation, Link } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'
import {Container, Row, Col, Spinner, Pagination, Button, Form } from 'react-bootstrap';
import axios from 'axios'
import FilterDropdown from '../components/FilterDropdown'

import InstanceCard from '../components/InstanceCardTeam'
import './Models.css'


const Teams = () => {
  const location = useLocation();
  const [teams, setTeams] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const[league, setLeague] = useState([]);
  const[leagues, setLeagues] = useState([]);
  const[stadiumCapacity, setStadiumCapacity] = useState([]);
  const[hasLogo, setHasLogo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); /* Inspired from IDB Group 6 */
  const sports_id = ['', 'Football', 'Tennis', 'Basketball', 'Ice Hockey', 'Volleyball', 'Handball'];
  const [sort, setSort] = useState('');
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [sport, setSport] = useState('');
  const [sports, setSports] = useState([]);
  const search = useRef("");

  const handleSort = (event) => {
    resetDropdowns();
    setSort(event);
    setLoaded(false);
  };

  const handleCountryFilter = (event) => {
    resetDropdowns();
    setCountry(event);
    setLoaded(false);
  };

  const handleSportFilter = (event) => {
    resetDropdowns();
    setSport(event);
    setLoaded(false);
  };

  const handleLeagueFilter = (event) => {
    resetDropdowns();
    setLeague(event);
    setLoaded(false);
  };

  const handleStadiumCapacityFilter = (event) => {
    resetDropdowns();
    setStadiumCapacity(event);
    setLoaded(false);
  };

  const handleHasLogoFilter = (event) => {
    resetDropdowns();
    setHasLogo(event);
    setLoaded(false);
  };

  function changePage(pageNum) {
    setCurrentPage(pageNum);
    setLoaded(false);
  }

  function resetDropdowns() {
    search.current.value = ""
    setSort("");
    setSport("");
    setCountry("");
    setLeague("");
    setStadiumCapacity("");
    setHasLogo("");
  }

  function highlight(info) {
    if (search.current.value !== "" && info !== null){
      info = info.toString()
      const words = search.current.value.split(" ")
      for (const word of words) {
        const regex = new RegExp(word, "gi");
        info = info.replace(regex, `<mark>${word}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{__html: info}}></span>
  }

  // const translator = ["","Football", "Tennis", "Basketball", "Ice Hockey", "Volleyball", "Handball"];
  useEffect(() => {
    var totalResponse = null;
    const loadTeams = async () => {
      try {
        var response = null;
        console.log("Search value: " + search.current.value);
        if (search.current.value != "") {
          response = (await axios.get("https://api.allthesports.info/teams/search", {
            params: {
              offset: currentPage,
              limit: 50,
              terms: search.current.value
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/search", {
            params: {
              terms: search.current.value
            }
          })).data.length;
          console.log("Search response: " + response);
        } else if (sort != "") {
          response = (await axios.get("https://api.allthesports.info//teams/sort", {
            params: {
              offset: currentPage,
              limit: 50,
              sort_type: sort
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/sort", {
            params: {
              sort_type: sort
            }
          })).data.length;
          console.log("Sorting: ", response)
        } else if (country != "") {
          response = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              offset: currentPage,
              limit: 50,
              filter_type: "country",
              filter_value: country
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              filter_type: "country",
              filter_value: country
            }
          })).data.length;
        } else if (sport != "") {
          response = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              offset: currentPage,
              limit: 50,
              filter_type: "sport",
              filter_value: sport
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              filter_type: "sport",
              filter_value: sport
            }
          })).data.length;
        } else if (league != "") {
          response = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              offset: currentPage,
              limit: 50,
              filter_type: "league",
              filter_value: league
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              filter_type: "league",
              filter_value: league
            }
          })).data.length;
        } else if (stadiumCapacity != "") {
          response = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              offset: currentPage,
              limit: 50,
              filter_type: "stadium_capacity",
              filter_value: stadiumCapacity
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              filter_type: "stadium_capacity",
              filter_value: stadiumCapacity
            }
          })).data.length;
        } else if (hasLogo != "") {
          response = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              offset: currentPage,
              limit: 50,
              filter_type: "has_logo",
              filter_value: hasLogo
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/teams/filter", {
            params: {
              filter_type: "has_logo",
              filter_value: hasLogo
            }
          })).data.length;
        } else {
          response = (await axios.get("https://api.allthesports.info/teams", {
            params: {
              offset: currentPage,
              limit: 50
            }
          }));
          totalResponse = (await axios.get("https://api.allthesports.info/total_teams")).data;
        }
        
        // Fill teams sports filter list
        if (sports.length === 0){
          var temp_sports = await axios.get("https://api.allthesports.info/sports", {
            params: {
              offset: currentPage,
              limit: 50
            }
          });
          setSports(temp_sports.data.map(sport => sport.name).filter(sport => sport !== 'Tennis'));
        }

        // Fill team countries filter list
        if (countries.length === 0){
          var temp_countries= await axios.get("https://api.allthesports.info/teams", {
            params: {
              offset: currentPage,
              limit: 150
            }
          });
          var temp_countries_list = temp_countries.data.map(team => team).filter(team => team.sport_id !== 2)
          temp_countries_list = [...new Set(temp_countries_list.map(team => team.country_name))]
          setCountries(temp_countries_list)
        }

        // Fill leagues filter list
        if (leagues.length === 0){
          var temp_leagues = await axios.get("https://api.allthesports.info/leagues", {
            params: {
              offset: currentPage,
              limit: 150
            }
          });
          setLeagues(temp_leagues.data.map(league => league.name));
        }

        const playerPromises = response.data.map(team => axios.get(`https://api.allthesports.info/players/team/${team.id}`));
        const leaguePromises = response.data.map(team => axios.get(`https://api.allthesports.info/leagues/${team.league_id}`));
        
        const playerResponses = await Promise.all(playerPromises);
        const leagueResponses = await Promise.all(leaguePromises);

        const updateTeams = response.data.map((team, index) => {
          return {
            ...team,
            players: playerResponses[index].data.name,
            league: leagueResponses[index].data.name
          }
        });

        setTeams(updateTeams);
        console.log("Response: ");
        console.log(updateTeams);

        setTotal(totalResponse);

        setLoaded(true);
      } catch (error) {
          console.error(error);
      }
      // finally {
      //   source.cancel('Request canceled');
      // }
    };
    if (!loaded) {
      loadTeams();
    }
  }, [currentPage, country, sport, league, stadiumCapacity, hasLogo, sort, search.current.value, countries.length, leagues.length, loaded, sports.length]);

  const numPages = (total % 50 === 0) ? total / 50 : Math.floor(total / 50) + 1;
  const pages = [];
  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <Pagination.Item key={i} onClick={() => changePage(i)}>
        {i}
      </Pagination.Item>
    );
  }

  return (
    <React.Fragment>
      <Container>
      <h1 className="header-1" style={{ fontSize: '3rem',  fontWeight: 'bold', borderBottom: "3px solid #b5b5b5", marginBottom: "20px", marginTop: "10px"}}>Teams &amp; Individual Athletes</h1>

          <Form onSubmit={(event) => {event.preventDefault();setLoaded(false);}}>
            <Row>

              <br />
              <Form.Control ref={search} type="search" placeholder="Search for a Team" className="mr-2" aria-label="Search"
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#fff3ee';
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#FFFFFF';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
                style={{ transition: 'background-color 0.2s ease-in-out, transform 0.3s',
                  transform: 'scale(1)', backgroundColor: '#fff'
                }} 
              />
                <Button variant="outline-success" onClick={() => { setLoaded(false); }}>Search</Button>
              </Row>
              <br />

              <Row>
                <Col>
                  <FilterDropdown title="Sort" items={["Name", "Stadium Capacity", "Country Name", "Stadium Name", "Manager Name"]} onChange={handleSort} color="info" />
                </Col>
                <Col>
                  <FilterDropdown title="Sport" items={sports} onChange={handleSportFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="Country" items={countries} onChange={handleCountryFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="League" items={leagues} onChange={handleLeagueFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="Stadium Capacity" items={["1-5000", "5001-10000", "10001-15000", "15001-20000", "20001-25000", "25001-30000", "30001-35000"]} onChange={handleStadiumCapacityFilter} />
                </Col>
                <Col>
                  <FilterDropdown title="Has Logo" items={["Yes", "No"]} onChange={handleHasLogoFilter} />
                </Col>
                <Col>
                  <Button variant="outline-danger" onClick={() => {resetDropdowns();setLoaded();}}>Clear Filters</Button>
                </Col>
              </Row>
              <br />
              <Row> 
            <hr className='line-1' />           
            </Row>
          </Form>
      <h5 className="header-2">Here are {total} Teams to look at!</h5>
      <hr className="line-1" />
      <p>
        Page {currentPage} of {numPages}
      </p>
      <Pagination className="justify-content-center">{pages}</Pagination>
      <p className="lead text-center mt-2 mb-4">
        Showing {1 + ((currentPage - 1) * 50)} to {teams.length + ((currentPage - 1) * 50)} of {total} results
      </p>

      <Container className="mt-4">
        <Row className="gap-4">
          {loaded
            ? teams.map((team) => (
                <InstanceCard
                  key={team.id}
                  id={team.id}
                  card_image={team.logo}
                  title={highlight(team.name)}
                  country={highlight(team.country_name)}
                  league={highlight(team.league)}
                  sport={highlight(sports_id[team.sport_id])}
                  stadium_capacity={highlight(team.stadium_capacity || "-")}
                  stadium_name={highlight(team.stadium_name != 'No information about stadium.' ? team.stadium_name : 'No Stadium')}
                  // League_Country = {highlight(league.country  != 'No information about host coun' ? league.country : 'No official host country')}
                  manager_name={highlight(team.manager_name != 'No information about manager n' ? team.manager_name : 'No manager')}
                  btn_prompt="View Team"
                />
              ))
            : <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" size = "10rem" />
              </div>}
        </Row>
        <Pagination className="justify-content-center">{pages}</Pagination>
      </Container>
    </Container>
    </React.Fragment>
  )
}

export default Teams
