import React from 'react'
import { useParams } from 'react-router-dom'
// import { Container, Row, Col, Button, Card} from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import axios from 'axios'
import { useEffect, useState } from 'react'



// Simulate fetching league data (actual api only fetches league that matches id)
// import leaguesData from '../data/LeaguesData'

const LeagueInstance = () => {
  const { id } = useParams()

  const [league, setLeague] = useState([]);
  const [sport, setSport] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [Team0, setTeam0] = useState([]);
  const [Team1, setTeam1] = useState([]);
  const [Team2, setTeam2] = useState([]);
  const [Team3, setTeam3] = useState([]);

  const [Player0, setPlayer0] = useState([]);
  const [Player1, setPlayer1] = useState([]);
  const [Player2, setPlayer2] = useState([]);
  const [Player3, setPlayer3] = useState([]);

  const [var0, setvar0] = useState([]);
  const [var1, setvar1] = useState([]);
  const [var2, setvar2] = useState([]);
  const [var3, setvar3] = useState([]);
  const [var4, setvar4] = useState([]);
  
  useEffect(() => {
    const loadLeague = async () => {
      axios.get("https://api.allthesports.info/leagues/" + id)
        .then(response => {
          setLeague(response.data)
          const startDate = new Date(response.data.start_date)
          const endDate = new Date(response.data.end_date)

          const formattedStartDate = `${startDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} on ${startDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}`;
          const formattedEndDate = `${endDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} on ${endDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}`;

          response.data.start_date = formattedStartDate;
          response.data.end_date = formattedEndDate;
          axios.get("https://api.allthesports.info/sports/" + response.data.sport_id)
            .then(response => {
              setSport(response.data)
            })
            .catch(error => {
              console.error(error);
            });
            axios.get("https://api.allthesports.info/teams/league/" + response.data.id)
            .then(response => {
              if (Array.isArray(response.data)) {
                const randomIndexes = new Set();
                const dataLength = response.data.length || 0;
                while (randomIndexes.size < 4 && randomIndexes.size < dataLength) {
                  const randomIndex = Math.floor(Math.random() * dataLength);
                  randomIndexes.add(randomIndex);
                }
                const result = Array.from(randomIndexes);
          
                setTeam0(response.data[result[0]]);
                setTeam1(response.data[result[1]]);
                setTeam2(response.data[result[2]]);
                setTeam3(response.data[result[3]]);
          
                axios.get("https://api.allthesports.info/players/team/" + response.data[result[0]].id)
                  .then(response => {setPlayer0(response.data[0])})
                  .catch(error => {console.error(error);});
                axios.get("https://api.allthesports.info/players/team/" + response.data[result[1]].id)
                  .then(response => {setPlayer1(response.data[0])})
                  .catch(error => {console.error(error);});
                axios.get("https://api.allthesports.info/players/team/" + response.data[result[2]].id)
                  .then(response => {setPlayer2(response.data[0])})
                  .catch(error => {console.error(error);});
                axios.get("https://api.allthesports.info/players/team/" + response.data[result[3]].id)
                  .then(response => {setPlayer3(response.data[0])})
                  .catch(error => {console.error(error);});
              }
            })
            .catch(error => {
              console.error(error);
            });
          
          axios.get("https://api.allthesports.info/facts/" + response.data.id)
            .then(response => {
              if (response.data.length > 0) {
                setvar0(response.data[0])
              }
              if (response.data.length > 1) {
                setvar1(response.data[1])
              }
              if (response.data.length > 2) {
                setvar2(response.data[2])
              }
              if (response.data.length > 3) {
                setvar3(response.data[3])
              }
              if (response.data.length > 4) {
                setvar4(response.data[4])
              }
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
      setLoaded(true);
    }
    if (!loaded) {      
      loadLeague();
    }
  });




  return (
    <React.Fragment>
    <Container fluid className="text-center">
      <Row>
        <Col
          md={{ span: 8, offset: 2 }}
          className="border border-2 rounded border-secondary mt-4 p-4"
        >
          {/* League Logo */}
            <img
              src={league.logo}
              alt="League Logo"
              style={{ width: "200px", height: "200px" }}
              className="mx-auto d-block"
            />
          <h1 className="display-6 ">{league.name}</h1>
          <hr />

          {/* Game Stats */}
          {var0.name ? (
          <Row className="mt-4">
          
            <h3 className="mb-4">League Facts</h3>
            <Col>
              <Row>
                <h4>{var0.desc ? var0.name : "1st fact"}</h4>
              </Row>
              <Row>
                <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                  {var0.desc ? var0.desc : "N/A" }
                </p>
              </Row>
            </Col>
            <Col>
              <Row>
                <h4>{var1.desc ? var1.name : "2nd fact"}</h4>
              </Row>
              <Row>
                <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                  {var1.desc ? var1.desc : "N/A"}
                </p>
              </Row>
            </Col>
            
            <Col>
              <Row>
                <h4>{var2.name ? var2.name :"3rd fact"}</h4>
              </Row>
              <Row>
                <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                  {var2.desc ? var2.desc : "N/A"} 
                </p>
              </Row>
            </Col>
          
          <Col>
            <Row>
              <h4>{var3.name ? var3.name : "4th fact"}</h4>
            </Row>
            <Row>
              <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                {var3.desc ? var3.desc : "N/A"}
              </p>
            </Row>
          </Col>

          <Col>
            <Row>
              <h4>{var4.name ? var4.name : "5th fact"}</h4>
            </Row>
            <Row>
              <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                {var4.desc ? var4.desc : "N/A"}
              </p>
            </Row>
          </Col>




            <Col>
              <Row>
                <h4>Sport</h4>
              </Row>
              <Row>
                <p className="text-muted" style={{ fontSize: "1.75rem" }}>
                  {sport.name ? sport.name : "N/A"}
                </p>
              </Row>
            </Col>
          </Row>
          ) : (
            <div>
            <Row className="mt-4">
              <h3 className="mb-4">No Data On This League.</h3>
            </Row>
            </div>
          )}

          <hr />

          {/* Misc */}
          <div>
            <Row className="mt-4">
              {/* Left Lane */}
              { Team0.id ? (
              <Col>
                <Row className="mt-4">
                  <h3 className="mb-4">Teams</h3>
                  <Col sm = {6} key={Team0.id}>
                  <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/teams/" + Team0.id}>
                        <img
                          src={Team0.logo}
                          alt="Team Logo"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Team0.name}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col sm = {6} key={Team1.id}>
                  <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/teams/" + Team1.id}>
                        <img
                          src={Team1.logo}
                          alt="Team Logo"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Team1.name}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col sm = {6} key={Team2.id}>
                  <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/teams/" + Team2.id}>
                        <img
                          src={Team2.logo}
                          alt="Team Logo"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Team2.name}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col sm = {6} key={Team3.id}>
                  <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/teams/" + Team3.id}>
                        <img
                          src={Team3.logo}
                          alt="Team Logo"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Team3.name}</h5>
                      </div>
                    </div>
                  </Col>
                </Row>                
              </Col>
              ) : (
                <div></div>
              )}
              <Col>
                <Row className="mt-4">
                    <h3 className="mb-4">Country</h3>
                </Row>
                <Row>
                  
                  <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                    <p>{league.country} </p>
                  </p>
                </Row>

                <Row className="mt-2">
                  <h4>League Start Date</h4>
                </Row>
                <Row>
                  <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                    {league.start_date ? league.start_date : "N/A"}
                  </p>
                </Row>

                <Row className="mt-2">
                  <h4>League End Date</h4>
                </Row>
                <Row>
                  <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                    {league.end_date ? league.end_date : "N/A"}
                  </p>
                </Row>
              </Col>
              { Player0 ? (
              <Col>
                <Row className="mt-4">
                  <h3 className="mb-4">Players</h3>
                  <Col>
                    {Player0 ? (
                    <div className="card" style={{width: "9rem", height: "9rem"}}>
                      <a href={"/players/" + Player0.id}>
                        <img
                          src={Player0.photo}
                          alt="Player"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Player0.name}</h5>
                      </div>
                    </div>
                    ) : ( <div></div> )}
                  </Col>
                  <Col>
                    {Player1 ? (
                      <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/players/" + Player1.id}>
                        <img
                          src={Player1.photo}
                          alt="Player"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Player1.name}</h5>
                      </div>
                    </div>
                    ) : ( <div></div> )}
                  </Col>
                  <Col>
                    {Player2 ? (
                      <div className="card" style={{width: "9rem", height: "9rem"}}>                      <a href={"/players/" + Player2.id}>
                        <img
                          src={Player2.photo}
                          alt="Player"
                          style={{ width: "65px", height: "65px" }}
                          className="mx-auto d-block"
                        />
                      </a>
                      <div className="card-body">
                        <h5 className="card-title">{Player2.name}</h5>
                      </div>
                    </div>
                    ) : ( <div></div> )}
                  </Col>
                  <Col>
                    {Player3 ? (
                      <div className="card" style={{width: "9rem", height: "9rem"}}>                        <a href={"/players/" + Player3.id}>
                          <img
                            src={Player3.photo}
                            alt="Player"
                            style={{ width: "65px", height: "65px" }}
                            className="mx-auto d-block"
                          />
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{Player3.name}</h5>
                        </div>
                        </div>
                    ) : ( <div></div> )}
                  </Col>
                </Row>
              </Col>
              ) : (
                <div></div>
              )}
            </Row>
          </div>

          { league.map_src &&
          <div>
            <Row className="mt-2">
              <h4>League Location Map</h4>
            </Row>
            <Row>
              <iframe
              title="League Location"
              width="600"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              referrerPolicy='no-referrer-when-downgrade'
              src={league.map_src}
              />
            </Row>
          </div>
          }


         


        </Col>
      </Row>
    </Container>
  </React.Fragment>
  )
}

export default LeagueInstance
