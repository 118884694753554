import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import {genleg} from '../assets/images/index'

const InstanceCard = ({
  id,
  card_image,
  title,
  League_Country,
  League_Start_Date,
  League_End_Date,
  League_Sport,
  League_Fact_Count,
}) => {
  const location = useLocation()

  if (card_image === 'https://tipsscore.com/resb/no-league.png') {
    card_image = genleg
  }

  return (
    <React.Fragment>
      <Card 
        style={{ 
          width: '15rem', 
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', 
          cursor: 'pointer', 
          transition: 'background-color 0.3s ease-in-out, transform 0.3s', 
          backgroundColor: '#fff3ee',
          transform: 'scale(1)'
        }} 
        onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#FFA07A'; e.currentTarget.style.transform = 'scale(0.95)' }}
        onMouseOut={(e) => { e.currentTarget.style.backgroundColor = '#fff3ee'; e.currentTarget.style.transform = 'scale(1)' }}
        onClick={() => window.location.href=`/leagues/${id}`}
      >
        <Card.Body className='text-center'>
          <div className='row align-items-center'>
            <Card.Title style={{ marginBottom: 0 }}>
              <b>{title}</b>
              <p style={{ fontSize: '15px' }}>
              ✭<b>{League_Country}</b>✭
              </p>
            </Card.Title>
          </div>
          <Card.Img src={card_image} />
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>
              A <b>{League_Sport} </b> league
            </p>
          </Card.Text>
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>
              With <b>{League_Fact_Count} facts </b>
            </p>
          </Card.Text>
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>
              <b>From</b>
            </p>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>{League_Start_Date}</p>
          </Card.Text>
          <Card.Text style={{ margin: '0.1rem 0' }}>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>
              <b>To</b>
            </p>
            <p style={{ fontSize: '15px', marginBottom: 0 }}>{League_End_Date}</p>
          </Card.Text>
        </Card.Body>
      </Card>
      <style jsx>{`
        .card-text p {
          margin-bottom: 0.25rem;
        }
      `}</style>
    </React.Fragment>
  )
}

export default InstanceCard
