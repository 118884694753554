import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector } from 'recharts'
import axios from 'axios'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >{`Teams: ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const getRawData = async () => {
  var teams = await axios.get('https://api.allthesports.info/teams')
  return teams.data
}

const TeamsPerSportPieChart = () => {
  const sports_id = [
    '',
    'Football',
    'Tennis',
    'Basketball',
    'Ice Hockey',
    'Volleyball',
    'Handball',
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  const [teams, setTeams] = useState([])

  useEffect(() => {
    getRawData().then((data) => setTeams(data))
  }, [])

  const sportFreq = []
  teams.forEach((team) => {
    if (team.sport_id in sportFreq) {
      sportFreq[team.sport_id]++
    } else {
      sportFreq[team.sport_id] = 0
    }
  })

  const data = []
  var curr_id = 0
  sportFreq.forEach((sport) => {
    data.push({ name: sports_id[curr_id], value: sport })
    curr_id++
  })

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  return (
    <div className='d-flex justify-content-center'>
      <PieChart width={1000} height={600}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data.slice(1, data.length)}
          cx='50%'
          cy='50%'
          innerRadius={170}
          outerRadius={240}
          fill='#8884d8'
          dataKey='value'
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </div>
  )
}

export default TeamsPerSportPieChart
