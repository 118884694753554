import {aaronpic, harmanpic2, bradypic, jeromepic, danielpic} from '../assets/images/index.js';

const team = [
    {
        name: "Jerome Conkright",
        gitlab_username: "jerome.conkright1",
        role: "Frontend Testing",
        bio:"I'm in my second year at UT Austin pursuing a degree in Computer Science with a minor in Spanish. I enjoy making espresso and practicing my Spanish in my free time.",
        tests: 22,
        email: "jerome.conkright@utexas.edu",
        image: jeromepic
    },
    {
        name: "Daniel Jeng",
        gitlab_username: "dbjeng",
        role: "Full Stack Developer, Database design, and Documentation",
        bio: "I'm a third year student at UT Austin pursuing a BS degree in Computer Science. I am originally from Colorado and enjoy fly fishing, snowboarding, and playing guitar.",
        tests: 26, 
        email: "dbjeng@gmail.com",
        image: danielpic
    },
    {
        name: "Aaron Lee",
        gitlab_username: "aaronlee232",
        role: "Backend Developer, Database design, Backend Testing",
        bio: "I'm a second year student at UT Austin pursuing a BS degree in Computer Science. I love discovering new recipes that I can cook and trying out new foods on campus.",
        tests: 12,
        email: "aaronlee232@gmail.com",
        image: aaronpic
    },
    {
        name: "Harman Sandhu",
        gitlab_username: "hasandhu",
        role: "FullStack Developer, Frontend Design", 
        bio: "I'm a second year student at UT Austin pursuing a BS in Computer science. I love staying up all night to go on long drives through west Austin in my 2000 C5 Corvette.",
        tests: 4,
        email: "hasandhu@utexas.edu",
        image: harmanpic2
    },
    {
      name: 'Brady Wilkin',
      gitlab_username: 'bradywilkin',
      role: "Frontend Developer",
      bio: "I'm a second year student at UT Austin pursuing a BS in Computer Science. I love playing the guitar and learning new songs to play for my friends.",
      tests: 4,
      email: 'bradywilkin@utexas.edu',
      image: bradypic
    },
  ]
  
    export default team