import React from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import SearchBar from './SearchBar'

const NavBar = () => {
  return (
    <Navbar style={{ backgroundColor: '#FFA07A' }}>
      <Container>
        <Navbar.Brand href='/' style={{ fontSize: '2rem' }}>
          AllTheSports
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Collapse id='basic-navbar-nav'>
          <Container className='d-flex justify-content-between'>
            <Nav className='me-auto'>
              <Nav.Link href='/' style={{ fontSize: '1.2rem' }}>
                Home
              </Nav.Link>
              <Nav.Link href='/about' style={{ fontSize: '1.2rem' }}>
                About
              </Nav.Link>
              <Nav.Link href='/leagues' style={{ fontSize: '1.2rem' }}>
                Leagues
              </Nav.Link>
              <Nav.Link href='/teams' style={{ fontSize: '1.2rem' }}>
                Teams
              </Nav.Link>
              <Nav.Link href='/players' style={{ fontSize: '1.2rem' }}>
                Players
              </Nav.Link>
              <Nav.Link href='/OurVisualizations' style={{ fontSize: '1.2rem' }}>
                Our Vis
              </Nav.Link>
              <Nav.Link href='/ProviderVisualizations' style={{ fontSize: '1.2rem' }}>
                Pro Vis
              </Nav.Link>
            </Nav>
            <SearchBar style={{ paddingLeft: '2rem' }} />
          </Container>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar
