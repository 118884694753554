import React from 'react'
import { Container } from 'react-bootstrap'
import LeagueCountryBarChart from '../components/charts/LeagueCountryBarChart'
import TeamsPerSportPieChart from '../components/charts/TeamsPerSportPieChart'
import PlayerAgeHistogram from '../components/charts/PlayerAgeHistogram'
import IngredientsCaloriesHistogram from '../components/charts/IngredientsCaloriesHistogram'
import RecipeNationalityPieChart from '../components/charts/RecipeNationalityPieChart'
import RestrauntRatingBoxPlot from '../components/charts/RestrauntRatingBoxPlot'

const OurVisualization = () => {
  // # Leagues in Each Country Choropleth
  // Get list of countries

  return (
    <React.Fragment>
      <Container>
        <h1 className='header-1 mt-4'>Visualizations</h1>
        <h3 className='header-1 mt-2'>Leagues in each Country</h3>
        <LeagueCountryBarChart />
        <h3 className='header-1 mt-2'>Teams in Each Sport</h3>
        <TeamsPerSportPieChart />
        <h3 className='header-1 mt-2'>Player Age Histogram</h3>
        <PlayerAgeHistogram />
      </Container>
    </React.Fragment>
  )
}

export default OurVisualization
